import {
	Radio,
	Label,
	Dialog,
	DialogTrigger,
	DialogContent,
	LinkButton,
	HorizontalRule,
	CheckList,
	CheckListItem,
} from '@troon/ui';
import { Show, For, createSignal, onMount } from 'solid-js';
import { createAsync, useParams } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { useTrackEvent } from '@troon/analytics';
import { AccessProducts } from '../../../../../../components/access-products';
import { getAccessProducts } from '../../../../../../providers/card';
import { TroonCardSubscriptionProductType } from '../../../../../../graphql';
import type { CourseTeeTimeRate, Currency } from '../../../../../../graphql';
import type { JSXElement } from 'solid-js';

type Props = {
	rate: CourseTeeTimeRate;
	isSelected: boolean;
	discounts: Record<TroonCardSubscriptionProductType, Currency | null | undefined>;
	original?: Currency;
};

const trackingDetails = { productType: 'troonAccess', location: 'tee time checkout' };

export function AccessCardUpsellRate(props: Props) {
	const params = useParams<{ facilityId: string; teeTimeId: string }>();
	const [cardUpsellOpen, setCardUpsellOpen] = createSignal(false);
	const accessProducts = createAsync(() => getAccessProducts({}));
	const trackEvent = useTrackEvent();

	onMount(() => {
		trackEvent('upsellVisible', trackingDetails);
	});

	return (
		<>
			<div class="relative flex flex-col gap-6 rounded bg-gradient-to-r from-neutral-950 to-brand-700 p-4 text-white md:p-6">
				<div class="flex flex-wrap justify-between gap-y-6">
					<Radio value={props.rate.id} checked={props.isSelected} disabled>
						<Label class="flex flex-col gap-1 ps-2">
							<span class="text-lg font-semibold">{props.rate.name}</span>
							<span>
								<Show when={props.original}>
									{(original) => (
										<>
											<span class="text-neutral-600 line-through">{original().displayValue}</span>{' '}
										</>
									)}
								</Show>
								{props.rate.price.displayValue} per member
							</span>
						</Label>
					</Radio>
					<div
						aria-hidden
						class="absolute inset-0 z-10"
						onClick={() => {
							setCardUpsellOpen(true);
							trackEvent('upsellClicked', trackingDetails);
						}}
					/>
					<Dialog key="access-products" open={cardUpsellOpen()} onOpenChange={setCardUpsellOpen}>
						<DialogTrigger appearance="primary" class="relative z-10 shrink grow-0">
							Buy now & save
						</DialogTrigger>
						<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
							<div class="flex flex-col items-center gap-4">
								<AccessProducts
									redirect={`/course/${params.facilityId}/reserve-tee-time/${params.teeTimeId}`}
									banners={Object.entries(props.discounts).reduce(
										(memo, [key, val]) => {
											if (!val) {
												return memo;
											}
											memo[key as TroonCardSubscriptionProductType] = (
												<p class="py-2 text-center">
													Save <b>{val.displayValue}</b> on your tee time.
												</p>
											);
											return memo;
										},
										{} as Record<TroonCardSubscriptionProductType, JSXElement>,
									)}
								/>
								<LinkButton href="/access" class="size-fit">
									Learn more about Troon Access <Icon name="arrow-right-md" />
								</LinkButton>
							</div>
						</DialogContent>
					</Dialog>
				</div>
				<Show when={props.discounts[TroonCardSubscriptionProductType.TroonAccessPlus]}>
					{(discount) => (
						<>
							<div class="opacity-20">
								<HorizontalRule />
							</div>
							<div class="flex flex-col gap-4">
								<p class="font-medium">
									Save <b>{discount().displayValue}</b> on this tee time when you join {props.rate.name}
								</p>
								<p class="text-sm text-neutral-300">Discount applies to the member only.</p>
							</div>
						</>
					)}
				</Show>
			</div>
			<Show
				when={accessProducts()
					?.products.flatMap((product) => product.valueProps)
					.filter((value, index, self) => self.indexOf(value) === index)}
			>
				{(valueProps) => (
					<CheckList class="grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-2">
						<For each={valueProps()}>{(prop) => <CheckListItem>{prop}</CheckListItem>}</For>
					</CheckList>
				)}
			</Show>
		</>
	);
}
