import { Dialog, DialogTrigger, DialogContent, LinkButton } from '@troon/ui';
import { Show, createSignal, onMount, createEffect } from 'solid-js';
import { useParams } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { useTrackEvent } from '@troon/analytics';
import { AccessProducts } from '../../../../../../components/access-products';
import { TroonCardSubscriptionProductType } from '../../../../../../graphql';
import type { CourseTeeTimeRate, Currency } from '../../../../../../graphql';
import type { JSXElement } from 'solid-js';

type Props = {
	rate: CourseTeeTimeRate;
	discounts: Record<TroonCardSubscriptionProductType, Currency | null | undefined>;
};

const trackingDetails = { productType: 'troonAccess', location: 'tee time checkout payment info' };

export function AccessUpsellPayment(props: Props) {
	const params = useParams<{ facilityId: string; teeTimeId: string }>();
	const [cardUpsellOpen, setCardUpsellOpen] = createSignal(false);
	const trackEvent = useTrackEvent();

	onMount(() => {
		trackEvent('upsellVisible', trackingDetails);
	});

	createEffect(() => {
		if (cardUpsellOpen()) {
			trackEvent('upsellClicked', trackingDetails);
		}
	});

	return (
		<Show when={props.discounts[TroonCardSubscriptionProductType.TroonAccessPlus]}>
			{(discount) => (
				<Dialog key="access-products" open={cardUpsellOpen()} onOpenChange={setCardUpsellOpen}>
					<DialogTrigger as={LinkButton} size="sm" class="-ms-2 block w-fit justify-start">
						Save <b>{discount().displayValue}</b> on this tee time when you join {props.rate.name} <Icon name="info" />
					</DialogTrigger>
					<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
						<div class="flex flex-col items-center gap-4">
							<AccessProducts
								redirect={`/course/${params.facilityId}/reserve-tee-time/${params.teeTimeId}`}
								banners={Object.entries(props.discounts).reduce(
									(memo, [key, val]) => {
										if (!val) {
											return memo;
										}
										memo[key as TroonCardSubscriptionProductType] = (
											<p class="py-2 text-center">
												Save <b>{val.displayValue}</b> on your tee time.
											</p>
										);
										return memo;
									},
									{} as Record<TroonCardSubscriptionProductType, JSXElement>,
								)}
							/>
							<LinkButton href="/access" class="size-fit">
								Learn more about Troon Access <Icon name="arrow-right-md" />
							</LinkButton>
						</div>
					</DialogContent>
				</Dialog>
			)}
		</Show>
	);
}
